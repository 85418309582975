import { useFetch } from "../hooks";
import { ProfileSubscription } from "../profile/type";
import { Loader } from "../components/LoadingComponent";
import { ActiveDashboard } from "./activeDashboard";
import { InactiveDashboard } from "./inactiveDashboard";

export const Dashboard = () => {

  const { data, loading } = useFetch<ProfileSubscription>("https://api.verygood.dev/profile");

  if (loading || !data) {
    return <Loader />;
  }

  const hasActiveSubscription = data.subscription_expiry && new Date(data.subscription_expiry) > new Date();

  const expiryTime = hasActiveSubscription ? new Date(data.subscription_expiry) : new Date();

  if(!hasActiveSubscription){
    return <ActiveDashboard expiryTime={expiryTime} />
  }

  return <InactiveDashboard />
}