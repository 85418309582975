import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {Auth0Provider} from "@auth0/auth0-react";


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
            <Auth0Provider
                domain="verygooddev.eu.auth0.com"
                clientId="n1rS66iOiGJS6MKYLvIzfHuLLlt3hQTS"
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: "https://academy.verygood.dev",
                }}
            >
                <App/>
            </Auth0Provider>
    </React.StrictMode>
);

