import styled from "styled-components";
import {ReactComponent as Logo} from "./logo_cropped.svg";

const Header = styled.header`
  flex-shrink: 0;
  display: flex;
  height: 80px;
  border-bottom: 1px solid rgb(246, 248, 250);
  width: 100%;
  padding: 4px;
`;

const FormattedLogo = styled(Logo)`
  width: 320px;
`;

export const Navbar = () => {

    return (
        <Header>
            <FormattedLogo/>
        </Header>
    );
};
