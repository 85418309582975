import { useFetchPost } from "../hooks";
import { Loader } from "../components/LoadingComponent";

export const InactiveDashboard = () => {
  const { post, loading } = useFetchPost("https://api.verygood.dev/subscribe", {
    followLocationHeaders: true,
  })

  const submit = async () => {
    await post({ lookup_key: "price_1MguyFGECCUzdwsWKglDCASd" });
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <section>
        <div className="product">
          <div className="description">
            <h3>Starter plan</h3>
            <h5>$20.00 / month</h5>
          </div>
        </div>

          <button id="checkout-and-portal-button" onClick={submit} type="submit">
            Checkout
          </button>
      </section>
    </div>
  );
};