type Props = {
  expiryTime?: Date
};

export const ActiveDashboard = (props: Props) => {
  return (
    <div>
      <span>Active Dashboard</span>
      {props.expiryTime && <span>Expiry Time: {props.expiryTime.toISOString()}</span>}
    </div>
  );
};