import styled, { keyframes } from "styled-components";


const spinningAnimation = keyframes`  0% {
                                        transform: rotate(0deg);
                                      }
                                        100% {
                                          transform: rotate(360deg);
                                        }`;

export const Loader = styled.div`

  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #69FF97 transparent #0D25B9 transparent;
    animation: ${spinningAnimation} 1.2s linear infinite;
  }
`;

