import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const useFetch = <T>(url: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    setData(undefined);
    setError(undefined);
    getAccessTokenSilently().then(token => {
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        setLoading(false);
        res.json().then(data => {
          setData(data);
        });
      }).catch(err => {
        setLoading(false);
        setError(err);
      });
    })
      .catch(err => {
        setLoading(false);
        setError(err);
      })
    ;
  }, [url, getAccessTokenSilently]);

  return { data, loading, error };
};

type PostOptions = {
  followLocationHeaders: boolean;
}

export const useFetchPost = <TRequest, TResponse>(url: string, opts?: PostOptions) => {
  const { getAccessTokenSilently  } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | undefined>(undefined);

  let followLocationHeaders = false;
  if (opts?.followLocationHeaders) {
    followLocationHeaders = true;
  }

  const post = async (body: TRequest) => {
    setLoading(true);
    setError(undefined);
    try {
      const token = await getAccessTokenSilently();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const locationHeader = res.headers.get('Location');
      console.log('locationHeader', locationHeader);
      res.headers.forEach(console.log);
      console.log('followLocationHeaders', followLocationHeaders);
      if (followLocationHeaders && locationHeader) {
        setLoading(false);
        window.location.href = locationHeader;
      }
      const data = await res.json() as TResponse;
      setLoading(false);
      return data;
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  return { post, loading, error };
};
