import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { Navbar } from "./components/navigation/navbar";
import { useAuth0 } from "@auth0/auth0-react";
import { Dashboard } from "./dashboard";
import { Loader } from "./components/LoadingComponent";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.section`
  height: 100%;
  flex-grow: 1; /* ensures that the container will take up the full height of the parent container */
  overflow-y: auto; /* adds scroll to this container */
`;

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    height: 100%;
    overflow: hidden;
    margin: 0;
    display: flex;
    box-sizing: border-box;
    background: white;
  }

  #root {
    width: 100%;
  }`;


export const App = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);


  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />
    }
  ]);
  const content = () => {
    if (isLoading || !isAuthenticated) {
      return <Loader />;
    }

    return <RouterProvider router={router} />;

  };

  return (
    <Wrapper>
      <GlobalStyle />
      <Navbar />
      <Content>
        {content()}
      </Content>
    </Wrapper>
  );
};

export default App;
